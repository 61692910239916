<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-dialog />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Signal Definition
        </v-btn>
      </v-col>
    </v-row>
    <v-alert outlined
      type="info"
      dismissible
      elevation="2"
      prominent
      icon="mdi-signal-variant"
    >
      <div>
        <h3>Understanding Signal Definitions</h3>
        <p>
          Signals are high-fidelity alerts generated by your monitoring tools (e.g., Sentry, Datadog, PagerDuty) and can be processed directly by UnStruct or ingested via the API for further action.
        </p>
        <p>Signal definitions determine how a signal is processed, enabling you to:</p>
        <ul>
          <li>Map case types to specific signals.</li>
          <li>Apply snooze and duplication rules for each signal.</li>
        </ul>
        <br>
        <p>After creating a definition, you can go to the plugins section to enable the signal for processing.</p>

      </div>
    </v-alert>

    <!-- Search Bar -->
    <v-text-field
      class="mb-1"
      v-model="q"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      clearable
    />
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
        <v-card class="ma-2 elevation-2">
          <!-- Signal Name and Primary Information -->
          <v-card-title class="justify-space-between">
            <div class="mb-2">{{ item.name }}</div>
            <v-chip :color="item.enabled ? 'success' : 'grey'" dark>
              <v-icon left>{{ item.enabled ? 'mdi-lightbulb-on-outline' : 'mdi-lightbulb-off-outline' }}</v-icon>
              {{ item.enabled ? 'Enabled' : 'Disabled' }}
            </v-chip>
          </v-card-title>

          <!-- Signal Details -->
          <v-card-subtitle>
            <v-chip :color="item.create_case && item.enabled ? 'secondary' : 'grey'" dark>
              <v-icon left>{{ item.create_case ? 'mdi-airplane-takeoff' : 'mdi-briefcase-remove-outline' }}</v-icon>
              {{ item.create_case ? 'Create Case' : 'Do Not Create Case' }}
            </v-chip>
            <v-chip :color="item.case_priority ? 'error' : 'grey'" dark class="ml-2">
              <v-icon left>{{ item.case_priority ? 'mdi-arrow-up-bold' : 'mdi-elevation-decline' }}</v-icon>
              {{ item.case_priority ? item.case_priority.name : 'No Priority' }}
            </v-chip>
            <!-- Add more chips for other attributes as needed -->
          </v-card-subtitle>

          <v-card-text>
            <div class="mb-2">
              <v-icon left>mdi-send</v-icon>
              Conversation Target: {{ item.conversation_target || 'None' }}
            </div>
            <div class="mb-2">
              <v-icon left>mdi-briefcase-check</v-icon>
              Case Type: {{ item.case_type ? item.case_type.name : 'No Type' }}
            </div>
            <!-- Include other important information -->
          </v-card-text>

          <!-- Actions and more -->
          <v-card-actions class="justify-end">
            <v-btn color="primary" text @click="createEditShow(item)">Edit</v-btn>
            <v-btn color="error" text @click="removeShow(item)">Delete</v-btn>
            <!-- Include other actions as necessary -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import NewEditDialog from "@/signal/NewEditDialog.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/signal/DeleteDialog.vue"
export default {
  name: "SignalTable",
  components: { NewEditDialog, DeleteDialog, SettingsBreadcrumbs },
  props: {
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", align: "left", width: "10%" },
        { text: "Variant", value: "variant", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "Owner", value: "owner" },
        { text: "Case Type", value: "case_type" },
        { text: "Case Priority", value: "case_priority" },
        { text: "", value: "external_url", sortable: false },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
      showEditSheet: false,
    }
  },
  computed: {
    ...mapFields("signal", [
      "table.loading",
      "table.options.descending",
      "table.options.filters.project",
      "table.options.itemsPerPage",
      "table.options.page",
      "table.options.q",
      "table.options.sortBy",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query", "params"]),
    ...mapFields("auth", ["currentUser.projects"]),
  },
  methods: {
    ...mapActions("signal", ["getAll", "createEditShow", "removeShow"]),
  },
  created() {
    this.project = [{ name: this.query.project }]
    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

}
</script>
